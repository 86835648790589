.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  text-align: center;
  color: white;
  width: 33%;
}
.container > h2 {
  margin: 0px;
}
.imgContainer {
  width: 230px;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: solid white 4px;
  transition: 0.5s;
  object-fit: cover;
}

.img:hover {
  width: 230px;
  height: 230px;
}

@media only screen and (max-width: 750px) {
  .container {
    width: 100%;
  }
}
