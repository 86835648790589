.controller {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.controllerLabel {
  margin-bottom: 7px;
}
.controllerDescripton {
  margin-top: 7px;
  text-align: center;
}

.remove {
  position: absolute;
  font-weight: normal;

  top: 0;
  right: 0;
}
.remove:hover {
  color: red;
  cursor: pointer;
}

.selectorRow {
  display: flex;
  justify-content: space-around;
  margin: 10px 10px;
}
.selectorRow > select {
  flex: 1;
  margin: 0px 10px 0px 0px;
  height: 32px;
  -webkit-appearance: menulist-button;
}
.addButton {
  width: 70px;
  height: 32px;
  font-size: 1em;
  border-radius: 5px;
  background-color: rgb(38, 146, 52);
  border: none;
  transition: 0.4s;
  cursor: pointer;
  text-align: center;
  padding: 0px;
  color: white;
}

.addButton:hover {
  background-color: rgb(32, 83, 39);
}
