.container {
}

.homeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 2em;
  color: #004d80;
}
.backgroundImage {
  position: absolute;

  object-fit: cover;
  position: fixed;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contentContainer > h1 {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #004d80;
}

/* PUBLICATION LIST */

.publicationsTop {
  display: flex;

  min-height: 220px;
}
.publicationsTop > div {
  margin-right: -30px;
  position: relative;
}
.publicationsTop > div > img {
  transition: 0.5s;
  box-shadow: -10px 1px 15px 0px rgb(41, 41, 41);
  object-fit: cover;
  cursor: pointer;
}
.publicationsTop > div:hover > img {
  margin: 0px 50px 20px 20px;
  box-shadow: 0px 0px 15px 0px rgb(41, 41, 41);
  transform: translateY(-1rem) rotate(3deg) scale(1.15);
}

/* PUBLICATION VERTICAL LIST */
.publicationsList {
  display: flex;
  margin-top: 30px;
  width: 80%;
}
.publicationsList .imgContainer > img {
  box-shadow: 0px 0px 15px 0px rgb(41, 41, 41);
  transform: translateY(0rem) rotate(-2deg);
}
.publicationsList .imgContainer {
  min-width: 250px;
}

.publicationsList .textContainer {
  margin-left: 40px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  border: 4px solid #004d80;
}
.publicationsList > div > h2 {
  cursor: pointer;
  margin-top: 0px !important;
}
.thumbnail {
  cursor: pointer;
}
.arrow,
.readMore {
  cursor: pointer;
}
.arrow {
  transition: 0.5s;
}
.readMore:hover .arrow {
  transform: translateX(1rem);
}
@media screen and (max-device-width: 750px) and (orientation: portrait) {
  /* Mobile Portrait*/
  .publicationsList > img {
    margin-bottom: 20px;
  }
  .publicationsList {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 750px) {
  .contentContainer > h1 {
    margin-bottom: 10px;
  }
  .publicationsList {
    width: 90%;
  }
  .publicationsList .textContainer {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.publicationsTopMobile {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
}

.publicationsTopMobile > div {
  margin: 5px;
  width: 30%;
}

.publicationsTopMobile > div > img {
  object-fit: contain;
}
