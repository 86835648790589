.allSliderControls {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 11px;
  box-sizing: content-box;
  padding-top: 5px;
  justify-content: space-evenly;
  position: relative;
}

.controlHeader {
  display: flex;
  justify-content: space-between;
}

.controlHeader > a {
  color: black;
  font-size: 1.8em;
  align-self: center;
}
#pageTitle {
  text-align: center;
  margin: 0;
  font-size: 24px;
  text-transform: uppercase;
  color: red;
  width: 250px;
  align-self: center;
}

.navIcon {
  font-size: 2em;
  padding-right: 5px;
  cursor: pointer;
}

.controlContainer {
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  height: 100vh;
  padding: 0px 5px 0px 5px;
  box-sizing: border-box;
  background-color: #d7d7d7;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 0 0 auto;

  /* justify-content: space-around; */
}

@media screen and (min-device-width: 950px) {
  .controlContainer {
    min-height: 690px;
  }
}
@media (min-device-width: 800px) and (max-width: 1150px) {
  /* desktop narrow window */
  .controlContainer {
    position: fixed;
    height: 100%;
    width: 360px;
  }
}

@media (min-device-width: 750px) and (max-device-width: 900px) {
  /* ipad */

  .controlContainer {
    position: fixed;
    width: 295px;
    height: 100%;
  }
  .allSliderControls {
    zoom: 0.8;
  }
  .controlWrapper {
    min-width: 295px !important;
    max-width: 295px !important;
  }
}

@media screen and (max-device-width: 750px) and (orientation: portrait) {
  /* Mobile Portrait*/
  .allSliderControls {
    margin-left: 0px;
  }

  .controlContainer {
    padding: 0px 2px 0px 2px;

    z-index: 3;
    position: fixed;
    height: 300px;
    width: 100% !important;
    min-width: unset !important;
    max-width: unset !important;
  }
  #pageTitle {
    font-size: 21px !important;
  }
  .controlHeader {
    margin-top: 0px !important;
  }

  .allSliderControls {
    max-height: unset !important;
  }

  .controlWrapper {
    min-width: unset !important;
    max-width: unset !important;
  }
}

@media screen and (max-device-width: 750px) and (orientation: landscape) {
  /* Mobile Landscape*/
  .controlHeader {
    margin-top: 0px !important;
  }

  .controlContainer {
    position: fixed;

    max-height: 100vh;
    width: 100% !important;
    min-width: unset !important;
    max-width: 330px !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    padding: 0 0 0 2px;
  }
  .allSliderControls {
    max-height: unset !important;
    margin: 0;
  }

  .controlWrapper {
    min-width: 330px !important;
    max-width: 330px !important;
  }
}

.controlWrapper {
  transition: padding 1s;
  min-width: 360px;
  max-width: 360px;
  z-index: 1;
}

.sliderLabel {
  font-size: 0.9em;
  letter-spacing: 0.2px;
  font-weight: 300;
  line-height: 0.6;
}

@media screen and (max-device-width: 750px) {
  .allSliderControls {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 0px;
    box-sizing: border-box;
    padding-top: 0px;
  }

  .sliderLabel {
    line-height: 1;
  }
}

.sliderWrapper {
  margin-top: -8px;
  width: 100%;
  padding-left: 5px;
}
@media screen and (max-device-width: 750px) {
  .sliderWrapper {
    margin-top: -4px !important;
  }
}

.sliderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
