.contentContainer {
  width: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formContainer > p {
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
}
.formContainer > p > a,
.formContainer > p > span {
  color: red;
}
.formContainer > p > a:hover,
.formContainer > p > span:hover {
  cursor: pointer;
  color: rgb(148, 35, 35);
}
.inputBox {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  margin: 5px;
  border: solid 2px rgb(114, 114, 114);
  font-family: unset;
}
.inputBox:focus {
  outline: none;
}

.message {
  width: 100%;
  max-width: 430px;
  height: 130px;
  text-align: left;
  font-size: 1em;
  margin-top: 15px;
}
.message::placeholder {
  text-align: center;
  font-size: 1.2em;
}

.submitButton {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  margin: 5px;
  border: solid 2px white;
  background-image: linear-gradient(310deg, #ffc7c7, #ff0101);
  transition: 0.5s;
}
.submitButton:focus {
  outline: none;
}
.submitButton:hover {
  cursor: pointer;
  color: white;
  width: 180px;
  height: 40px;
  font-size: 1.3em;
  background-image: linear-gradient(310deg, #c32a3f, red);
}

.formSucess {
  color: green;
  font-weight: bold;
  font-size: 1.2em;
  margin: 5px;
}
.formError {
  color: red;
  font-weight: bold;
  font-size: 1.2em;
  margin: 5px;
}
