.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  animation: opacity 0.8s;
}
.outerFrame {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.popupContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  animation: zoom 0.8s;
}
.popupContentContainer {
  background: white;
  padding: 3% 10%;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  position: relative;
  box-shadow: 0 0 20px rgb(61, 61, 61);
}
.popupContentContainer > h1 {
  font-size: calc(1.6rem + 1.1vw);
  margin: 0.2em;
}
.popupContentContainer > h3 {
  margin: 0px;
}
.popupContentContainer > p {
  text-align: center;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  color: white;
  font-size: 1.5em;
  transition: 0.3s;
  cursor: pointer;
}

.score {
  font-size: calc(2rem + 1.1vw) !important;
  margin: 0.2em;
}

.leaderBoardLink {
  display: flex;
  margin-top: 0.5em;
}
.leaderBoardLink > button {
  padding: 5px 10px;
  font-size: 0.8em;
  background: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.5s;
  cursor: pointer;
}
.leaderBoardLink :first-child {
  margin-right: 10px;
}
.leaderBoardLink > button:hover {
  background-color: rgb(66, 79, 255);
  color: white;
}

.userLink {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
}
.userLink > input {
  width: 100%;
  max-width: 30em;
  height: 100%;
  line-height: 30px;
}
.userLink > button {
  height: 100%;
  font-weight: bold;
  background-color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.5s;
  cursor: pointer;
  margin-left: 5px;
}
.userLink > button:hover {
  background-color: rgb(66, 79, 255);
  color: white;
}

@media screen and (max-device-width: 900px) {
  .popupContainer {
    align-items: start;

    margin: 2.5%;
    width: 95%;
  }
  .popupContentContainer {
    padding: 3% 5%;
  }
  .score {
    font-size: calc(1.8rem + 1.1vw) !important;
  }
  .popupContentContainer > h1 {
    font-size: calc(1.3rem + 1.1vw);
  }
}

@keyframes zoom {
  0% {
    margin-top: -100px;
    margin-left: 250px;
    transform: scale(0.6);
  }

  100% {
    margin-top: 0px;
    margin-left: 0px;
    transform: scale(1);
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
