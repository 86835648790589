.capacityDisplay {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: solid;
  border-width: 2px;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26%;
  margin-left: 8px;
  font-size: 0.8em;
  padding: 3px;
  background: rgba(255, 255, 255, 0.59);
  box-shadow: 0 0 5px rgb(193, 193, 193);
}

.sliderInputArea {
  margin: 2px 0px 2px 0px;
}

.sliderInputArea > input {
  padding: 0px 4px;
  background-color: white;
  width: 40px;
  font-size: 1em;
  height: 21px;
  text-align: center;
  border: solid 1px grey;

}
.sliderInputArea .inputSuffix {
  padding: 2px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 32px;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  height: 21px;
}

.costTooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.costTooltip .costTooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.costTooltip .costTooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.costTooltip:hover .costTooltiptext {
  visibility: visible;
  opacity: 1;
}
