.horizontalSlider {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  box-sizing: unset;
}

.thumbMarker {
  z-index: -1;
  position: absolute;
  left: 50%;
  height: 190%;
  width: 2px;
  text-align: center;
  color: rgb(255, 255, 255);
  cursor: grab;
}
.thumbMarkerBefore {
  /* z-index: -1; */
  position: absolute;
  left: 0%;
  height: 130%;
  width: 50%;
  border-radius: 5px 0 0 5px;
}
.thumbMarkerInital {
  /* z-index: -1; */
  position: absolute;
  /* left:8%; 8% to 92% */
  height: 149%;
  width: 2px;
  text-align: center;
  color: rgb(255, 255, 255);
  cursor: grab;
  /* (max*84/value)+8 =  */
}
.thumbText {
  z-index: 2;
  padding: 0 5% 0 5%;
  border-radius: 5px;
  font-size: 0.7em;
}

.rangeSlider {
  background-image: url("./sliderBackground.png");
  border-radius: 5px;
  position: relative;
  font-size: 20px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 246px;
}
