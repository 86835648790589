.userScoreWrapper {
  margin: 6px;
  width: 100%;
  cursor: pointer;
}

.userScoreInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-device-width: 750px) {
  /* mobile any orientation */
  .userScoreWrapper {
    order: 1;
    width: 100%;
    height: 91px;
  }
}
@media screen and (max-width: 1100px) {
  /*narrow desktop screen */
}

@media screen and (max-device-width: 750px) {
  /* Mobile */
  .userScoreWrapper {
    width: 100%;
  }
}
@media screen and (min-device-width: 750px) and (max-device-width: 900px) and (orientation: portrait) {
  /* ipad portrate */
  .userScoreWrapper {
    width: 100%;
    order: 1;
  }
}
.userScoreInnerContainer > h1 {
  margin: 0px;
}

.userScoreInnerContainer > h3 {
  margin: 0px;
}
