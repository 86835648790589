.simplifiedChartTop,
.simplifiedChartBottom {
  box-shadow: 0px 0px 10px #3d4849;
  text-align: center;
  max-width: 33%;
  margin: 6px;
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 1%;
}

.simplifiedChartTop {
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  position: relative;
}
.simplifiedChartTop > h1 {
  margin: 2% 0px;
  line-height: 20px;
}
.simplifiedChartTop > h3 {
  margin: 2px 0px 2px 0px;
  line-height: 20px;
}

.imgContainer {
  position: relative;
  z-index: 2;
  /* max-height: 45%; */
  display: flex;
  flex: 1;
  max-height: min-content;
}
.imgContainer > img {
  object-fit: contain;
  align-self: flex-end;
  object-position: 50% 100%;
}
.guageChartContainer > svg {
  width: 100%;
}

.simplifiedChartBottom {
  font-weight: bold;
  color: white;
  min-height: 150px;
}

.simplifiedChartTop > p,
.simplifiedChartBottom > p {
  text-align: center;
  margin: 0px;
}
@media screen and (max-device-width: 750px) {
  .simplifiedChartTop {
    min-height: 250px;
  }
}
@media screen and (max-device-width: 750px) and (orientation: landscape) {
  .simplifiedChartBottom > p {
    font-size: 0.5em;
  }
}
