.pageContainer {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  width: calc(100vw - 360px);
  height: 100%; /* ADDED FOR REACT */
}
.controlTitle {
  font-size: 2.5em;
  margin: 30px 0 2% 0;
  color: white;
}
@media (min-device-width: 750px) {
  /* not Mobile */
  .pageContainer {
    min-height: 595px;
  }
}

@media screen and (max-device-width: 750px) {
  /* mobile any orientation */
  .pageContainer {
    align-items: center;
    height: 100vh !important;
    width: 100% !important;
    overflow-y: hidden !important;
  }
}
@media (min-device-width: 750px) {
  .pageContainer {
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
@media screen and (min-device-width: 750px) and (max-device-width: 900px) {
  /* ipad portrate */
  .controlTitle {
    margin: 20px 0 1% 0;
  }
  .pageContainer {
    width: 100%;
  }
}

@media (min-device-width: 800px) and (max-width: 1150px) {
  /* desktop narrow window */
  .pageContainer {
    height: fit-content;
    width: 100%;
  }
}

@media screen and (max-device-width: 750px) and (orientation: portrait) {
  /* Mobile Portrait*/
  .pageContainer {
    max-width: 100vh !important;
  }
}

@media (max-device-width: 750px) {
  .controlTitle {
    margin: 20px 0 20px 0;
  }
}

.controlCloseText {
  font-size: 2.5em;
  margin: 10px 0 2% 0;
  color: white;
}
