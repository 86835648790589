.navContainer {
  height: 0px;
  position: fixed;
  z-index: 5;
  overflow-y: hidden;
  border-right: solid 2px lightgrey;
  transition: height 1s;
  box-shadow: 10px 0px 10px 0px rgb(0 0 0 / 13%);
  background-image: linear-gradient(310deg, #3870b7 27%, #297dc5 96%);
}
.navClose {
  position: absolute;
  right: 15px;
  top: 15px;
  color: white;
  font-size: 1.5em;
  transition: 0.3s;
  cursor: pointer;
}

.nav {
  font-size: 1.5em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0%;
  font-weight: 300;
  letter-spacing: 0.1em;
  height: 100%;
}

.nav > a {
  line-height: 40px;
  color: rgb(240, 240, 240);
}

.nav > div {
  cursor: pointer;
  line-height: 40px;
  color: rgb(240, 240, 240);
}
