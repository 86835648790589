.contentContainer {
  background-color: rgb(222, 222, 222);
  width: 80%;
  padding: 1em 2em 1em 2em;
  line-height: normal;
  text-align: left;
  border: 6px solid;
  overflow-x: hidden;
  max-height: 73vh;
  overflow-y: scroll;
  box-shadow: 0px 0px 24px rgb(127, 170, 235);
}
a:hover {
  color: rgb(158, 0, 8);
}
a {
  text-decoration: none;
  line-height: 20px;
  color: rgb(218, 18, 18);
}

.contentContainer > p,
.contentContainer > ul,
ol {
  line-height: 24px;
}

.contentContainer > table > thead > tr > th {
  padding: 0px 5px;
  text-align: center;
}

.contentContainer > table > thead > tr > th:first-of-type {
  padding: 0px 0px;
  text-align: left;
}

.contentContainer > table > tbody > tr > td {
  text-align: center;
}

.contentContainer > table > tbody > tr > td:first-of-type {
  text-align: left;
}

@media screen and (min-device-width: 900px) {
  .contentContainer::-webkit-scrollbar {
    width: 12px;
  }
  .contentContainer::-webkit-scrollbar-thumb {
    width: 8px;

    border: solid black 1px;
    border-radius: 0px;
    background-color: rgb(255, 255, 255);
  }
}

@media only screen and (min-device-width: 750px) and (max-device-width: 900px) {
  /* ipad portrate */
  .contentContainer {
    margin-top: 15px;
  }
}
@media screen and (max-device-width: 750px) {
  /* Mobile Portrait*/
  .contentContainer {
    width: 90%;
    max-height: 60vh;
    padding: 15px !important;
  }
  .contentContainer > p {
    line-height: 20px;
  }
}
