.MEGSContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.headingImgContainer {
  max-width: 250px;
  height: 80px;
  z-index: 5;
  margin: 20px;
  margin-bottom: 0px;
}

.backgroundIcon {
  position: absolute;
  left: -40px;
  background-size: contain;
  top: 100px;
  opacity: 0.1;
  z-index: 0;
  max-height: 80vh;
}
.backgroundGraph {
  position: absolute;
  right: 0px;
  background-size: contain;
  bottom: 0px;
  opacity: 0.2;
  width: 80%;
  z-index: 0;
}

.MEGSContainer > p {
  width: 90%;
  text-align: center;
  color: white;
}
.chartsContainer {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
  width: 90%;
  margin: 10px;
}

.chartInnerCard {
  background: #fff;
  min-width: 0;
  min-height: 0;
  padding: 20px;
  position: relative;
  box-shadow: 0px 0px 10px #3d4849;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (min-device-width: 750px) {
  .chartsContainer {
    min-width: 410px;
  }
}

@media screen and (max-device-width: 750px) {
  .headingImgContainer {
    max-width: 150px;
    height: 45px;
  }
  .MEGSContainer > p {
    font-size: 8px;
  }
}
