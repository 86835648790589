.landingPageWrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.socials {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
}

.socialIcons {
  padding: 4px;
  cursor: pointer;
  font-size: 50px;
}
.socialIcons:hover {
  color: black;
}
.welcomeContainer {
  background-image: linear-gradient(310deg, #578ac9 23%, #004d80 73%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  min-height: 800px;
}
.contentContainer {
  width: 90%;
  max-width: 1100px;

  display: flex;
  min-height: fit-content;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 30px 0px;
}

.contentContainer > h1 {
  text-align: center;
  margin: 0px 0px 2px 0px;
  font-size: 2.5em;
}

.contentContainer > p {
  text-align: center;
  line-height: 25px;
  background-color: white;
  color: black;
  padding: 10px 10px 20px 10px;
  border: solid 6px rgb(221, 0, 0);

  position: relative;
  height: 100px;
  overflow: hidden;
  transition: 0.3s;
}
.contentContainer > p:hover {
  height: 120px;
}

.aboutUsShadow {
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0px -41px 46px 0px white;
  transition: 0.3s;
}
.aboutUsShadow:hover {
  box-shadow: inset 0px -19px 20px 0px white;
}
.circleContainer {
  display: flex;
}
.videoContainer > h3 {
  text-align: center;
  margin-top: 2px;
  color: rgb(218, 218, 218);
}
.videoiframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  flex: 1;
}

.selectionBoxes {
  height: 100%;
  width: 48%;
  max-width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1%;
  align-items: center;
}
.selectionBox {
}

.screenshot {
  border: 5px solid white;
  box-shadow: 0px 0px 15px #413f3f;
}
.controlFlagContainer > h1 {
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 0;
}
.controlFlags {
  transition: transform 0.3s;
  flex-direction: row;
  display: flex;
  align-self: center;
  padding: 3%;
}
.controlFlags .flag {
  flex: 1;
  object-fit: contain;
  min-width: 0;
  padding: 0% 4%;
  transition: 0.3s;
}
.controlFlags .flag:hover {
  transform: scale(1.15);
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .landingPageWrapper {
    height: unset;
  }

  .contentContainer > h1 {
    margin: 30px 0px 2px 0px;
  }

  .circleContainer {
    display: flex;
    flex-direction: column;
  }

  .landingPageContainer {
    height: unset;
  }
  .contentContainer {
    width: 100%;
    height: unset;
    padding: 0px 1em 1em 1em;
    text-align: center;
  }
  .selectionBoxes {
    padding: 0px 1em 1em 1em;
    width: 100%;
    max-width: unset;
  }
  .selectionBoxInnerContainer {
    width: 100%;
  }

  .socials {
    top: 5px;
    right: 5px;
  }

  .socialIcons {
    font-size: 40px;
  }
}
.advertContainer {
  width: 80%;
}
.advertImage {
  box-shadow: 0px 0px 15px #413f3f;
  border: 5px solid white;
  max-width: 80%;
  transition: 0.3s;
}
.advertImage:hover {
  transform: scale(1.1);
}

.selectionBoxes > h3 {
  color: white;
  margin-bottom: 10px;
}

.customShapeDividerTop {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
  overflow: hidden;
  line-height: 0;
}

.customShapeDividerTop svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 65px;
  transform: rotate(180deg);
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.customShapeDividerTop .shapeFill {
  fill: rgb(226, 226, 226);
  box-shadow: inset 0 0 10px #000000;
}
