#root {
  min-height: 100%;
  height: 100% !important;
}

* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  line-height: 40px;
  color: white;
}

html {
  height: 100%;
  max-width: 100%;
}
body {
  min-height: 100%;
  height: 100% !important;
}
body {
  font-family: 'Inter var', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    Helvetica, sans-serif;
  margin: 0px;
  width: 100%;
  height: fit-content;
  box-shadow: 00px 00px 300px 300px rgba(0, 0, 0, 0) inset;
  background-blend-mode: lighten;
  /* background-image: linear-gradient(310deg, #b6d7ff 23%, #004d80 73%); */
}
body::before {
  background-image: url('https://modelling.energy/wp-content/uploads/2020/05/sutty-background.png');
}

body:after {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* background-color: rgba(255, 255, 255, 0.5); */
}
img {
  max-width: 100%;
  max-height: 100%;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}

h1 {
  font-size: calc(1rem + 1.1vw);
}
h2 {
  font-size: calc(0.9rem + 0.9vw);
}
h3 {
  font-size: calc(0.8rem + 0.6vw);
}

@media only screen and (max-device-width: 950px) {
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.9em;
  }
  p {
    font-size: 0.8em;
  }
  li {
    font-size: 0.8em;
  }
  table {
    font-size: 0.8em;
  }
}

@media only screen and (max-device-width: 950px) and (orientation: landscape) {
  h1 {
    font-size: calc(0.9rem + 0.6vw);
  }
}
@media only screen and (min-device-width: 750px) and (max-device-width: 900px) {
  html,
  body {
    overflow-x: hidden;
  }
  p {
    font-size: 0.8em;
  }
  li {
    font-size: 0.8em;
  }
}
