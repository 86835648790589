.simpleViewContainer {
  width: 100%;
  flex-direction: row;
  display: flex;
  max-height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 410px;
  max-width: 1400px;
  position: relative;
}
.simpleChartContainer {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.simpleChartContainerTop {
  display: flex;
  flex: 1 1;
}
.simpleChartContainerBottom {
  display: flex;
  flex: 0.4 1;
  min-height: fit-content;
}
/* @media screen and (min-device-width: 950px) {
  .simplifiedChartContainer {
    min-width: 175px;
    min-height: 650px;
  }
} */
@media screen and (max-width: 1100px) {
  .simpleChartContainer {
    width: 100%;
  }
  /*narrow desktop screen */
  .simplifiedChartContainer {
    width: 33%;
  }
}
/* 
@media screen and (max-device-width: 750px) {
 .simplifiedChartContainer {
    justify-content: flex-start;
    min-height: 420px;
  }
} */
.detailedViewContainer {
  max-height: 100%;
  width: 25%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.socialScore {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-width: 135px;
  flex: 1;
}
/* .averageCharts {
  display: flex;
  height: 100%;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
} */
@media screen and (max-width: 1100px) {
  /*narrow desktop screen */
  /* .averageCharts {
    width: 66%;
  } */
  .socialScore {
    min-width: 33%;
    align-content: flex-start;
  }
  .detailedViewContainer {
    width: 100%;
    max-width: unset;
  }
}
@media screen and (max-device-width: 750px) {
  /* .averageCharts {
    width: 100%;
  } */
  .socialScore {
    order: 1;
    width: 100%;
  }
}

@media screen and (max-device-width: 750px) {
  /* mobile any orientation */
  .simpleViewContainer {
    min-width: unset;
  }

  .detailedViewContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: min-content;
  }
  .socialScore {
    order: -1;
  }
  /* .simplifiedChartContainer {
    min-width: 33%;
    width: 33%;
  } */
}
@media screen and (min-device-width: 750px) and (max-device-width: 900px) and (orientation: landscape) {
  /* ipad landscape */
  .detailedViewContainer {
    flex-direction: row;
    width: 100% !important;
    max-width: unset;
  }
}

@media screen and (min-device-width: 750px) and (max-device-width: 900px) and (orientation: portrait) {
  /* ipad portrate */
  .detailedViewContainer {
    flex-wrap: wrap;
  }
}
