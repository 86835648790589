/* MOBILE ONLY */
.container {
  display: flex;
  width: 50%;
  height: 20%;
  justify-content: space-between;
  padding: 3px;
  max-height:43px;
}
@media screen and (max-device-width: 750px) and (orientation: landscape) {
.button {
  width: 30% !important;
  
}


}
.button {
  width: 40%;
  height: 38px;

  border-radius: 5px;
  border-color: black;
  touch-action: manipulation;
}
.sign {
  position: relative;
  font-size: 1.8em;
  line-height: 10px;
}
.label {
  line-height: 13px;
  font-weight: 400;
  font-size: 0.9em;
}


.labelContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.capacityContainer {
  font-size: 1em;
  display: flex;
  max-width: 90%;
  justify-content: center;
}
.capacityValue {
  width: 60%;
  text-align: center;
  font-size: 0.8em;
  border-radius: 5px 0px 0px 5px;
  height: 20px;
}
.capacitySuffix {
  border: solid 1px grey;
  font-size: 0.8em;
  background-color: lightgrey;
  padding: 0px 2px;
  border-radius: 0px 5px 5px 0px;
}

