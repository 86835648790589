.container {
  background-color: rgb(233, 233, 233);
  background-image: linear-gradient(
      transparent 11px,
      rgba(255, 255, 255, 0.8) 12px,
      transparent 12px
    ),
    linear-gradient(
      90deg,
      transparent 11px,
      rgba(255, 255, 255, 0.8) 12px,
      transparent 12px
    );

  background-size: 100% 12px, 12px 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 800px;
  min-height: 650px;
  box-shadow: inset 0px 8px 6px -6px rgb(0, 0, 0), inset 0px -8px 6px -6px #888;
  position: relative;
}
.container > h1 {
  padding: 20px 0px 0px 0px;
  font-size: 50px;
  z-index: 5;
  color: #09639e;
}

.contentContainer {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 500px;
  z-index: 5;
  height: 100%;
}
.imgContainer {
  width: 55%;
  margin: 20px;
  flex: 1;
}
.img {
  object-fit: contain;
  background-position: center;
  align-self: flex-start;
  z-index: 5;
}

.backgroundImage {
  position: absolute;
  right: 0px;
  object-fit: cover;
  bottom: 0px;
  opacity: 0.05;
  width: 80%;
  z-index: 0;
}
.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 45%;
  z-index: 5;
  box-shadow: 0 0 10px black;
  background-image: linear-gradient(310deg, #578ac9 23%, #004d80 73%);
  padding: 20px;
  margin: 20px;
  color: white;
}

.textContent > h1,
.textContent > p {
  margin: 5px;
}

.controlFlags {
  display: flex;
}
.controlFlags .flag {
  object-fit: contain;
  min-width: 0;
  padding: 4% 4%;
  transition: 0.3s;
}
.controlFlags .flag:hover {
  transform: scale(1.15);
  cursor: pointer;
}

.customShapeDividerTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  overflow: hidden;
  line-height: 0;
}

.customShapeDividerTop svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 65px;
  /* transform: rotate(180deg); */
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.customShapeDividerTop .shapeFill {
  fill: rgb(43, 119, 180);
  box-shadow: inset 0 0 10px #000000;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon {
  font-size: 50px;
  transition: 0.5s;
}
.icon:hover {
  color: #00c234;
  transform: translateX(10px);
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .container {
    height: 100%;
    max-height: unset;
  }

  .container > h1 {
    padding: 20px 0px 0px 20px;
    line-height: 40px;
    font-size: 35px;
    text-align: center;
  }
  .contentContainer {
    flex-direction: column;
  }
  .textContent {
    flex-direction: column;
    width: 90%;
  }
  .imgContainer {
    width: 90%;
  }
  .controlFlags .flag {
    object-fit: contain;
    min-width: 0;
    padding: 0% 4%;
    margin: 10px;
    transition: 0.3s;
  }
}
