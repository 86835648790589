.container {
  background-color: rgb(88, 88, 88);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 1000px;
  min-height: 500px;
  position: relative;
  box-shadow: inset 0px 8px 6px -6px rgb(0 0 0);
}

.contentContainer {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 500px;
}

.backgroundImage {
  position: absolute;

  background-size: contain;
  background-position: center;

  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.container > h1 {
  padding: 0px;
  margin-bottom: 10px;
  font-size: 50px;
  z-index: 5;
  color: white;
}

.mainImageContainer {
  width: 40%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  z-index: 5;
  margin: 20px;
  color: white;
  cursor: pointer;
}

.mainImageContainer > h3 {
  margin: 0px;
}

.mainImageContainer > h4 {
  margin: 5px;
  font-weight: bold;
  color: white;
}

.img {
  margin: 20px;
  height: 70vh;
  max-height: 700px;
  background-size: contain;
  background-position: center;
  border: solid 2px black;
  z-index: 5;
  transition: 0.3s;
  cursor: pointer;
}

.img:hover {
  border: solid 3px red;
}

.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
  z-index: 5;
  color: white;
}

.textContent > h1 {
  margin: 10px;
}

.button {
  color: #099e36;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0px 30px 0px;
  border: 2px solid black;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
  background-color: white;
}

.button:hover {
  background-color: #099e36;
  color: white;
}

.publicationsList {
  display: flex;

  min-height: 220px;
}
.publicationsList > div {
  margin-right: -30px;
  position: relative;
}
.publicationsList > div > img {
  transition: 0.5s;
  box-shadow: -10px 1px 15px 0px rgb(41, 41, 41);
  object-fit: cover;
  cursor: pointer;
}
.publicationsList > div:hover > img {
  margin: 0px 50px 20px 20px;
  box-shadow: 0px 0px 15px 0px rgb(41, 41, 41);
  transform: translateY(-1rem) rotate(3deg) scale(1.15);
}

@media only screen and (max-width: 750px) {
  .container {
    height: 100%;
    max-height: unset;
  }

  .container > h1 {
    font-size: 35px;
  }
  .contentContainer {
    flex-direction: column;
  }
  .mainImageContainer {
    width: 90%;
  }
  .textContent {
    flex-direction: column;
    width: 90%;
  }

  .publicationsList {
    min-height: 150px;
  }
}
