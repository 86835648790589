.tooltiptext {
  visibility: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  top: 0;
  display: flex;
  align-items: center;
  background-color: rgba(27, 42, 62, 0.93);
  color: white;
  font-size: 0.9em;

  letter-spacing: 1.1;
  line-height: 1.5em;
  padding: 0 5px;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 10;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.chartsInfoIcon {
  position: absolute;
}

@media only screen and (max-width: 970px) {
  .tooltiptext {
    line-height: 1em !important;
  }
  .chartsInfoIcon {
    margin-left: 45px;
    margin-top: -20px;
  }
}
