.buttonRow {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5px;
  align-items: center;
  position: relative;
}
.controlsButton {
  width: 70px;
  height: 32px;
  font-size: 1em;
  border-radius: 5px;
  background-color: rgb(38, 146, 52);
  border: none;
  transition: 0.4s;
  cursor: pointer;
  text-align: center;
  padding: 0px;
  color: white;
}

.controlsButton:hover {
  background-color: rgb(32, 83, 39);
}

.tooltiptext {
  visibility: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  top: -200px;
  display: flex;
  align-items: center;
  background-color: rgba(27, 42, 62, 0.93);
  color: white;
  font-size: 0.9em;

  letter-spacing: 1.1;
  line-height: 1.5em;
  padding: 0 5px;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
@media only screen and (max-width: 970px) {
  .tooltiptext {
    line-height: 1em !important;
  }
}
