.systemGood {
  position: absolute;
  color: green;
  top: 1%;
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 45px;
  text-shadow: -0.05em -0.05em 0 #fff, 0.05em -0.05em 0 #fff,
    -0.05em 0.05em 0 #fff, 0.05em 0.05em 0 #fff;
}
.reserveShortfall {
  text-align: center;
  position: absolute;
  color: orange;
  top: 1%;
  font-size: 1.5em;
  font-weight: bold;
  animation: blinker 1.2s linear infinite;
  padding-left: 45px;
  text-shadow: -0.05em -0.05em 0 #fff, 0.05em -0.05em 0 #fff,
    -0.05em 0.05em 0 #fff, 0.05em 0.05em 0 #fff;
}

.blackout {
  position: absolute;
  color: red;
  top: 1%;
  font-size: 1.5em;
  font-weight: bold;
  animation: blinker 0.7s linear infinite;
  padding-left: 45px;
  text-shadow: -0.05em -0.05em 0 #fff, 0.05em -0.05em 0 #fff,
    -0.05em 0.05em 0 #fff, 0.05em 0.05em 0 #fff;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
