.chartsContainer {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
}

@media (min-device-width: 750px) {
  .chartsContainer {
    min-width: 410px;
  }
  .chartWrapper {
    min-height: 595px;
  }
}

@media (min-device-width: 750px) {
  .chartWrapper {
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.chartWrapper {
  background-image: linear-gradient(310deg, #b6d7ff 23%, #004d80 73%);
  background-blend-mode: overlay;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* ADDED FOR REACT */
  justify-content: center; /* added */
}

@media screen and (max-device-width: 750px) and (orientation: portrait) {
  /* Mobile Portrait*/

  .chartWrapper {
    padding-top: 300px;
    height: unset !important;
    max-width: 100% !important;
  }
}

@media screen and (max-device-width: 750px) and (orientation: landscape) {
  /* Mobile Landscape*/

  .chartWrapper {
    padding-left: 0px;
  }
}
