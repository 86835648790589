.container {
  background-image: linear-gradient(333deg, #c95766 23%, #800000 59%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 650px;
  max-height: 800px;
  position: relative;
}

.contentContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.container > h1 {
  padding: 20px 0px;
  font-size: 50px;
  color: #ffffff;
}
.headingImg {
  max-width: 300px;
  background-size: contain;
  background-position: center;
  z-index: 5;
  margin: 40px;
}
.backgroundIcon {
  position: absolute;
  left: -45px;
  background-size: contain;
  bottom: -40px;
  opacity: 0.2;
  z-index: 0;
}
.backgroundGraph {
  position: absolute;
  right: 0px;
  background-size: contain;
  bottom: 0px;
  opacity: 0.2;
  width: 80%;
  z-index: 0;
}
.mainImageContainer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  z-index: 5;
  height: 100%;
  max-height: 500px;
  margin: 20px;
}
.img {
  object-fit: contain;
  border: solid 2px black;
  z-index: 5;
  transition: 0.3s;
  cursor: pointer;
}

.img:hover {
}

.textContent {
  display: flex;
  height: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 5;
  width: 50%;
}

.textContent > h1 {
  margin: 0px;
}
.textContent > p,
.textContent > .buttons {
  margin: 30px;
}

.controlFlags {
  display: flex;
}
.controlFlags .flag {
  flex: 1;
  object-fit: contain;
  min-width: 0;
  padding: 0% 4%;
  transition: 0.3s;
}
.controlFlags .flag:hover {
  transform: scale(1.15);
  cursor: pointer;
}
.buttonRow {
  display: flex;
  width: 100%;

  justify-content: center;
}
.buttons {
  display: flex;
  flex-direction: column;
}
.buttons > button {
  color: #e51119;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  margin: 5px 10px;
  border: 2px solid black;
  border-radius: 10px;
  transition: 0.3s;
  background-color: white;
}

.buttons > button:hover {
  background-color: #e51119;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .container {
    height: 100%;
    max-height: unset;
  }

  .container > h1 {
    padding: 20px 0px 0px 20px;
    line-height: 40px;
    font-size: 35px;
    text-align: center;
  }
  .contentContainer {
    flex-direction: column;
  }
  .textContent {
    flex-direction: column;
    width: 90%;
  }
  .mainImageContainer {
    width: 90%;
  }

  .backgroundIcon {
    top: 0px;
  }
  .controlFlags .flag {
    margin: 10px;
  }
  .buttons {
    margin-bottom: 20px;
  }
  .buttons > button {
    font-size: 14px;
  }
}
