.button{
width:55px;
height:30px;
font-size:1em;
border:none;
background-color:rgb(224, 224, 224);
transition: 0.4s;
cursor: pointer;
text-align: center;
padding:0px;
}

.button:first-child{
    box-shadow: rgb(179, 179, 179) -5px 0px 5px inset;
    border-radius: 5px 0px 0px 5px;
}
.button:last-child{
    box-shadow: rgb(179, 179, 179) 5px 0px 5px inset;
    border-radius: 0px 5px 5px 0px;
}
.button.selected{
box-shadow: none !important;
background-color: rgb(38, 146, 52);
color:white;
}
 .button.selected:hover {
  box-shadow: none !important;
  background-color: rgb(33, 99, 42) !important;
  color: white !important;
} 

.buttonPair{
  padding:2px;
} 


.button:not(.selected):hover {
    background-color: rgb(105, 105, 105);box-shadow: none !important;
color: white;

}

@media screen and (max-device-width: 750px) and (orientation: landscape) {

.button{
  width: 50px !important;

font-size:0.9em  !important;
}
}

