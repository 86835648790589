.site {
  display: flex;
  position: relative;
  z-index: 0;
  transition: padding 1s;
}

.preLoadMessage {
  font-size: 2em;
  text-align: center;
  padding-top: 40vh;
  color: white;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(310deg, #b6d7ff 23%, #004d80 73%);
  font-family: monospace;
}
.pageContainerGeneric {
  position: absolute;
  margin-left: 360px;
  top: -100vh;
  transition: 2s;
  z-index: 10;
}

.pageContainerCharts {
  position: absolute;
  margin-left: 360px;
  top: 0;
  bottom: 0%;
  transition: 2s;
  z-index: 0;
  min-height: 100%;
}
@media screen and (min-device-width: 950px) {
  .pageContainerCharts {
    width: calc(100% - 360px);
  }
  .site {
    min-width: 770px;
  }
}
@media screen and (max-device-width: 750px) {
  /* Mobile */
  .pageContainerGeneric {
    height: 100vh;
    background-color: #000000a3;
    margin-left: unset;
  }
  .site {
    position: static;
  }
  .pageContainerCharts {
    position: static !important;
    margin-left: unset !important;
  }
}
@media (min-device-width: 750px) {
  /* slim desktop */
  .site {
    height: 100%;
  }
}

@media screen and (max-device-width: 750px) {
  /* Mobile any orientation */
  .site {
    height: fit-content;
  }
}

@media screen and (max-device-width: 750px) and (orientation: portrait) {
  /* Mobile Portrait*/

  .site {
    flex-direction: column !important;
  }
}
@media screen and (max-device-width: 750px) and (orientation: landscape) {
  /* Mobile Landscape*/
}

@media only screen and (min-device-width: 750px) and (max-device-width: 900px) {
  /*ipad */
  .site {
    height: 100vh;
  }

  .pageContainerGeneric {
    height: 100%;
  }
  .pageContainerCharts,
  .pageContainerGeneric {
    margin-left: 295px !important;
  }
}
